import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constDefault } from '../const_default';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

interface listData {
  data: any;
}

interface booleanData {
  status:Boolean;
}

interface successData{
	status:boolean,
	message:any,
	id:number,
	name:string,
	parent_id:number,
	parentName:string,
}
interface serviceData {
	status:any;
  data: any;
  count: number;
}
interface addData{
	data:any,
	status:number,
  message:string,
} 

interface bankReconc {
  status: any;
  data: any;
  search: any;
  opening: number;
  actual_opening: number;
}
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  headersdata:any={};
  authValue:any={};
  user_id:string;
  login_token:string;
	login_year:string;
  
constructor(private http: HttpClient, private session:SessionStorageService,private local_storage:LocalStorageService) { 

  this.authValue = this.local_storage.get('localAuthData');
  //console.log(this.authValue);
  if(this.authValue){
    this.user_id=this.authValue.user_id;
    this.login_token=this.authValue.token;
    this.login_year=this.authValue.financial_year_id;
    // console.log(this.login_year);
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('user', this.user_id);
    headers = headers.append('year', this.login_year);
    this.headersdata = headers.append('token', this.login_token);
  }
}

  /*------------------------------ Services By Roshan Bagde -------------------------------*/

  /*------------------------------ Services For Group Master -------------------------------*/

  /*
  Add New Group for Group-Master
  27.12.18
  Roshan Bagde  
  */
  addNewGroup(adddata){
  return this.http.post<addData>(constDefault.API_URL+"/accounts/addGroup/"+this.login_year,adddata,{headers:this.headersdata})
  }

   /*
  Get Group Data for Group-Master
  27.12.18
  Roshan Bagde  
  */
  getGroupList(pageno:number,petro_id:number,searchValue:string){
		return this.http.post<serviceData>(constDefault.API_URL+"/accounts/groupList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }

   /*
  Get Edit Data for Group-Master
  27.12.18
  Roshan Bagde  
  */
  getEditGroupData(group_id:number){
    return this.http.get<successData>(constDefault.API_URL+"/accounts/getGroupDataById/"+group_id+'/'+this.login_year,{headers:this.headersdata});
  }

   /*
  Submit Edit Group Data for Group-Master
  27.12.18
  Roshan Bagde  
  */
  editGroup(editData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/editGroup/"+this.login_year,editData,{headers:this.headersdata});
  }

  /*------------------------------ Services For Ledger Master -------------------------------*/
  /*
  Add New Ledger Entry for Ledger-Master
  27.12.18
  Roshan Bagde  
  */
 addNewLedger(adddata){
  return this.http.post<addData>(constDefault.API_URL+"/accounts/addLedger/"+this.login_year,adddata,{headers:this.headersdata})
  }

   /*
  Get Ledger Data for Ledger-Master
  27.12.18
  Roshan Bagde  
  */
  getLedgerList(pageno:number,petro_id:number,searchValue:string){
		return this.http.post<serviceData>(constDefault.API_URL+"/accounts/ledgerList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }

   /*
  Get Edit Data for Ledger-Master
  27.12.18
  Roshan Bagde  
  */
  getEditLedgerData(ledger_id:number){
    return this.http.get<addData>(constDefault.API_URL+"/accounts/getLedgerDataById/"+ledger_id+'/'+this.login_year,{headers:this.headersdata});
  }

   /*
  Submit Edit Ledger Data for Ledger-Master
  27.12.18
  Roshan Bagde  
  */
  editLedger(editData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/editLedger/"+this.login_year,editData,{headers:this.headersdata});
  }

   /*
  Get Data for Ledger Opening Amount
  28.12.18
  Roshan Bagde  
  */
  getLedgerOpenningById(group_id:number,petro_id:number){
    return this.http.get<addData>(constDefault.API_URL+"/accounts/getLedgerOpenningById/"+group_id+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
  }

 /*
  Add Data for Ledger-Opening-Amount
  28.12.18
  Roshan Bagde  
  */
  addLedgerOpenning(editdata){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/addLedgerOpenning/"+this.login_year,editdata,{headers:this.headersdata});
  }

  // getLedgerDataById(ledger_id:number){
  //   return this.http.get<successData>(constDefault.API_URL+"/accounts/getLedgerDataById/"+ledger_id,{headers:this.headersdata});
  // }
  
    /*
  Get Cash And bank Ledger List
  01.01.19
  Amit sahu
  */
  getCashAndBankLedgerList(petro_id:number){
    return this.http.get<addData>(constDefault.API_URL+"/accounts/getCashAndBankLedgerList/"+petro_id +'/'+this.login_year,{headers:this.headersdata});
  }


/*------------------------------ Services For Payment Voucher -------------------------------*/
  /*
    Add Payment Voucher Data
    04.01.19
    Roshan Bagde
    */
  addPaymentVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/addPaymentVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }

  /*
    Get Payment Voucher List
    04.01.19
    Roshan Bagde
    */

  getPaymentVouchers(pageno:number,petro_id:number,searchValue:string){
    return this.http.post<serviceData>(constDefault.API_URL+"/accounts/paymentVoucherList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }

  /*
    Get Ledger List
    04.01.19
    Roshan Bagde
    */
  getLedgerListForVoucher(petro_id:number){
    return this.http.get<serviceData>(constDefault.API_URL+"/accounts/getLedgerList/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
  }
    
  /*
    Get Payment Voucher info By id
    04.01.19
    Roshan Bagde
  */
  getPaymentVoucherDataById(voucher_id:number){
    return this.http.get<addData>(constDefault.API_URL+"/accounts/viewPaymentVoucher/"+voucher_id+'/'+this.login_year,{headers:this.headersdata});
  }

  /*
    Edit and Submit Payment Voucher Info
    04.01.19
    Roshan Bagde
  */
  editPaymentVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/editPaymentVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }


/*------------------------------ Services For Receipt Voucher -------------------------------*/
  /*
    Add Receipt Voucher Data
    09.01.19
    Roshan Bagde
    */
  addReceiptVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/addReceiptVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }

  /*
    Get Receipt Voucher List
    09.01.19
    Roshan Bagde
    */

  getReceiptVouchers(pageno:number,petro_id:number,searchValue:string){
    return this.http.post<serviceData>(constDefault.API_URL+"/accounts/receiptVoucherList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }
    
  /*
    Get Receipt Voucher info By id
    09.01.19
    Roshan Bagde
  */
  getReceiptVoucherDataById(voucher_id:number){
    return this.http.get<addData>(constDefault.API_URL+"/accounts/viewReceiptVoucher/"+voucher_id+'/'+this.login_year,{headers:this.headersdata});
  }

  /*
    Edit and Submit Receipt Voucher Data 
    09.01.19
    Roshan Bagde
  */
  editReceiptVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/editReceiptVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }


  /*------------------------------ Services For Contra Voucher -------------------------------*/
  /*
    Add Contra Voucher Data
    10.01.19
    Roshan Bagde
    */
   addContraVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/addContraVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }

  /*
    Get Contra Voucher List
    10.01.19
    Roshan Bagde
    */

  getContraVouchers(pageno:number,petro_id:number,searchValue:string){
    return this.http.post<serviceData>(constDefault.API_URL+"/accounts/ContraVoucherList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }
    
  /*
    Get Contra Voucher info By id
    10.01.19
    Roshan Bagde
  */
  getContraVoucherDataById(voucher_id:number){
    return this.http.get<addData>(constDefault.API_URL+"/accounts/viewContraVoucher/"+voucher_id+'/'+this.login_year,{headers:this.headersdata});
  }

  /*
    Edit and Submit Contra Voucher Data 
    10.01.19
    Roshan Bagde
  */
    editContraVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/editContraVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }


   /*------------------------------ Services For Journal Voucher -------------------------------*/
  /*
    Add Journal Voucher Data
    10.01.19
    Roshan Bagde
    */
   addJournalVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/addJournalVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }

  /*
    Get Journal Voucher List
    10.01.19
    Roshan Bagde
    */

  getJournalVouchers(pageno:number,petro_id:number,searchValue:string){
    return this.http.post<serviceData>(constDefault.API_URL+"/accounts/JournalVoucherList/page:"+pageno+'/'+petro_id+'/'+this.login_year,searchValue,{headers:this.headersdata})
  }
    
  /*
    Get Journal Voucher info By id
    10.01.19
    Roshan Bagde
  */
  getJournalVoucherDataById(voucher_id:number){
    return this.http.get<addData>(constDefault.API_URL+"/accounts/viewJournalVoucher/"+voucher_id+'/'+this.login_year,{headers:this.headersdata});
  }

  /*
    Edit and Submit Journal Voucher Data 
    10.01.19
    Roshan Bagde
  */
    editJournalVoucher(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/editJournalVoucher/"+this.login_year,addData,{headers:this.headersdata});
  }
  
  /*
    Amit Sahu
    10.01.19
    Get Bank Reconciliation data
  */
    getBankDataForReconciliation(petro_id:number,searchData:any){
    return this.http.post<bankReconc>(constDefault.API_URL+'/accounts/getBankReconciliationData/'+petro_id+'/'+this.login_year,searchData,{headers:this.headersdata})
  }
  
  /*
    Amit Sahu
    10.01.19
    Get Bank Reconciliation data Load More
  */
    getBankLoadMoreData(petro_id:number,last_id:number,searchData:any){
    return this.http.post<bankReconc>(constDefault.API_URL+'/accounts/loadMoreReconciliationData/'+petro_id+'/'+last_id+'/'+this.login_year ,searchData,{headers:this.headersdata})
  }
  /*
  Amit Sahu
  Bank Reconcilation 
  31.09.19
  */
  
   bankReconcilation(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/bankReconcilation/"+this.login_year,addData,{headers:this.headersdata});
  }
  /*
  Amit Sahu
 Cheque Cleared
  31.09.19
  */
  
   bankReconcilationChequeCleared(addData){
    return this.http.post<addData>(constDefault.API_URL+"/accounts/bankReconcilationChequeCleared/"+this.login_year,addData,{headers:this.headersdata});
  }

  /*
  Amit Sahu
 Cheque Cleared
  31.09.19
  */
  
 getInvoiceByCustId(cust_id:number, petro_id:number){
  return this.http.get<addData>(constDefault.API_URL+"/accounts/getCreditInvoiceList/"+cust_id+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
}
getInvoiceByCustIdNew(cust_id:number, petro_id:number){
  return this.http.get<addData>(constDefault.API_URL+"/accounts/getCreditInvoiceListNew/"+cust_id+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
}

getInvoiceByCustIdPayment(cust_id:number, petro_id:number){
  return this.http.get<addData>(constDefault.API_URL+"/accounts/getCreditInvoiceListPayment/"+cust_id+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
}

  /*
  Amit Sahu
 Add Stock Variation
  20.05.19
  */
  
 addStockVariation(data:any){
  return this.http.post<addData>(constDefault.API_URL+"/accounts/addStockVariation/"+this.login_year,data,{headers:this.headersdata});
}
 /*
  Amit Sahu
 get Stock Variation list
  20.05.19
  */
  
 getStockVariationListData(pageno:number,petro_id:number){
  return this.http.get<serviceData>(constDefault.API_URL+"/accounts/stockVariationList/page:"+pageno+"/"+petro_id+'/'+this.login_year,{headers:this.headersdata});
}
  
  /*
 Mrunali D
 gstni number
 30.7.2019
  */
  
 getGstniNo(ledger_id:number){
  return this.http.get<addData>(constDefault.API_URL+"/accounts/getGstniNumber/"+ledger_id+'/'+this.login_year,{headers:this.headersdata});
}  
  
  
} 
