import { Component,	OnInit } from '@angular/core';
import {	FormGroup, FormControl,	Validators,	FormBuilder, FormArray } from '@angular/forms';
import {	LocalStorageService,SessionStorageService,LocalStorage,	SessionStorage } from 'angular-web-storage';
import {	AccountService } from '../../../admin-services/account.service';
import {	ShiftSaleService } from '../../../admin-services/shift-sale.service';
import { CommonsService } from '../../../admin-services/commons.service';
import {	constDefault } from '../../../const_default';
import {	MasterServicesService } from '../../../admin-services/master-services.service';
import { DatePipe } from '@angular/common';
import {Router} from '@angular/router';
// Date Picker
import {	DatepickerOptions } from 'ng2-datepicker';
//import * as enLocale from 'date-fns/locale/en';
import * as frLocale from 'date-fns/locale/en';
declare var $: any;

@Component({
 selector: 'app-receipt-voucher',
 templateUrl: './receipt-voucher.component.html',
 styleUrls: ['./receipt-voucher.component.css']
})
export class ReceiptVoucherComponent implements OnInit {
 
 // Options For To Date (Search)
 options_form: DatepickerOptions = {
  minYear: 2015,
  maxYear: 2030,
  displayFormat:'DD-MM-YYYY',
  barTitleFormat: 'MMMM YYYY',
  dayNamesFormat: 'dd',
  firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
  locale: frLocale,

  //minDate: new Date(Date.now()), // Minimal selectable date
  //maxDate: new Date(Date.now()),  // Maximal selectable date

  barTitleIfEmpty: 'Click to select a date',
  placeholder: 'From Date', // HTML input placeholder attribute (default: '')
  addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
  addStyle: {
   'width': '100%'
  }, // Optional, value to pass to [ngStyle] on the input field
  fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
  useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
 };

 // Options For To Date (Search)
 options_to: DatepickerOptions = {
  minYear: 2015,
  maxYear: 2030,
  displayFormat: 'DD-MM-YYYY',
  barTitleFormat: 'MMMM YYYY',
  dayNamesFormat: 'dd',
  firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
  locale: frLocale,

  //minDate: new Date(Date.now()), // Minimal selectable date
  //maxDate: new Date(Date.now()),  // Maximal selectable date

  barTitleIfEmpty: 'Click to select a date',
  placeholder: 'To Date', // HTML input placeholder attribute (default: '')
  addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
  addStyle: {
   'width': '100%'
  }, // Optional, value to pass to [ngStyle] on the input field
  fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
  useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
 };

 // Options Date (Modal)
 options: DatepickerOptions = {
  minYear: 2015,
  maxYear: 2030,
  displayFormat: 'DD-MM-YYYY',
  barTitleFormat: 'MMMM YYYY',
  dayNamesFormat: 'dd',
  firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
  locale: frLocale,

  //minDate: new Date(Date.now()), // Minimal selectable date
  //maxDate: new Date(Date.now()),  // Maximal selectable date

  barTitleIfEmpty: 'Click to select a date',
  placeholder: 'Select Date', // HTML input placeholder attribute (default: '')
  addClass: 'form-control ', // Optional, value to pass on to [ngClass] on the input field
  addStyle: {
   'width': '100%'
  }, // Optional, value to pass to [ngStyle] on the input field
  fieldId: 'my-date-id', // ID to assign to the input field. Defaults to datepicker-<counter>
  useEmptyBarTitle: true, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
 };
 constructor(private accountService: AccountService, private commonsServices: CommonsService,private shiftSaleService:ShiftSaleService, private session: SessionStorageService, private masterService: MasterServicesService, private formBuilder: FormBuilder, private router: Router) {
   
 
 }

  pipe: any;
  dmy_shift_date: any;
 authData: any = {};
 addVoucher: any = {};
 editVoucher: any = {};
 cashBankLedgerList: any[] = [];
 ledgerListData: any[] = [];
 success_message: string;
 error_message: string;
 petro_id: number;
 addVoucherList: FormArray;
 addVoucherForm: FormGroup;
 editVoucherForm:FormGroup
 credit_ledger: number;
 debit_ledger: number;
 voucher_ledger_type: number;
 serachVoucherData: any = {};
 currentPage: number;
 totalPayVoucher: number;
 voucherList: any[] = [];
 editVoucherList: FormArray;
 index: number;
 model: string;

 invoiceList: any;
 editinvoiceList: any;
 billToBillList: FormArray;
 editbillToBillList: FormArray;
 customer_id: number;
 vehicleDropdownList: any[] = [];
 no_vehicle_info: string;
 customer_list = [];
 miscllenious_ledger: number;

 link_path:string;
 delete_btn:number;
 edit_btn:number;
 create_btn:number;
 
 btnPrevSession:any[]=[];
 
 searchReceiptVoucherForm = new FormGroup({
  from_date: new FormControl(''),
  to_date: new FormControl('')
 });

 viewVoucherForm = new FormGroup({
  id: new FormControl(''),
  name: new FormControl(' ', Validators.required),
 })

 


 ngOnInit() {
   // Button Privilages
   this.btnPrevSession = this.session.get('ButtonAuth');
   if(this.btnPrevSession)
   {
     let urlArr=this.router.url.split('/');
     this.link_path=urlArr[2];
     let result = this.btnPrevSession.find( prev => prev.NavMaster.routing ==this.link_path);
     this.delete_btn=result.UserPrivilege.delete_auth;
     this.edit_btn=result.UserPrivilege.edit_auth;
     this.create_btn=result.UserPrivilege.create_auth;
     this.serachVoucherData.from_date=new Date(Date.now());
     this.serachVoucherData.to_date=new Date(Date.now());
   }
   // End Button Privilages
   
  $("#cheque_info").hide();
  // Session read for petro id
  this.authData = this.session.get('authData');
  //console.log(this.authData)
  this.petro_id = this.authData.petro_id;
  this.credit_ledger = constDefault.CREDIT_LEDGER;
  this.debit_ledger = constDefault.DEBIT_LEDGER;
  this.voucher_ledger_type = constDefault.CASH_LEDGER;

  this.addVoucherForm = this.formBuilder.group({
   date: new FormControl('', Validators.required),
   ledger: new FormControl('', Validators.required),
   cheque_no: new FormControl(''),
   cheque_date: new FormControl(''),
   vehicle_no: new FormControl(''),
   amount: new FormControl('', Validators.required),
   narration: new FormControl(''),
   addVoucherList: this.formBuilder.array([this.addVoucherItem()]),
   billToBillList: this.formBuilder.array([]),
  });

  this.editVoucherForm = this.formBuilder.group({
    vid: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
    edit_invoice_type: new FormControl(''),
    ledger_name: new FormControl('', Validators.required),
    cheque_no: new FormControl(''),
    cheque_date: new FormControl(''),
    amount: new FormControl('', Validators.required),
    narration: new FormControl(''),
    editVoucherList: this.formBuilder.array([]),
    editbillToBillList: this.formBuilder.array([]),
   });

  this.addVoucher.date = new Date(Date.now());
  this.addVoucher.cheque_date = new Date(Date.now());

  // Cash and Bank list
  this.accountService.getCashAndBankLedgerList(this.petro_id).subscribe(ledgerdata => {
   this.cashBankLedgerList = Object.entries(ledgerdata.data).map(([value, label]) => ({
    value,
    label
   }));
  })
  // Ledger List
  this.accountService.getLedgerListForVoucher(this.petro_id).subscribe(ledgerdata => {
   this.ledgerListData = Object.entries(ledgerdata.data).map(([value, label]) => ({
    value,
    label
   }));
   //console.log(this.ledgerListData);	
  })
  this.pageWiseData(1);

 }


 // Show check info options if selected ledget value is not cash for Add Receipt Voucher
 show_check_options() {
  if (this.addVoucher.ledger.value != this.voucher_ledger_type) {
   $("#cheque_info").show();
  } else {
   $("#cheque_info").hide();
   $("#edit_cheque_info").hide();
  }
 }

 // Show check info options if selected ledget value is not cash for Edit Receipt Voucher
 show_edit_check_option(value: any) {
  if (value.value != this.voucher_ledger_type) {
   $("#edit_cheque_info").show();
  }
  else {
   $("#cheque_info").hide();
   $("#edit_cheque_info").hide();
  }
 }

 // Check Miscllenious Customer for add Receipt Ledger id = 14
 check_add_miscllenious_cust(value) {
  //alert("ledger_id");
  

  if (value.value == this.miscllenious_ledger) {

    this.shiftSaleService.getMisclleniousCustList(this.petro_id)
      .subscribe(misCustList => {
        this.customer_list = Object.entries(misCustList.data).map(([value, label]) => ({value,label}));
      })
    $("#show_miscllenious").show();
    $("#cheque_info").hide();
  } else {
    $("#show_miscllenious").hide();
  }

  // getGroupIdByLedgerId
  this.commonsServices.getGroupIdByLedgerId(value.value, this.petro_id)
    .subscribe(groupIdByLedgerId => {
      if (groupIdByLedgerId.status == 204) {
        $('#billToBill').hide();
        $('#billToBillListInfo').hide();
        $('#editbillToBillListInfo').hide();
        //this.getInvoiceByCustId(this.customer_id);

      }
      //console.log(groupIdByLedgerId);
      else if (groupIdByLedgerId.data.Ledger.group_id == constDefault.SUNDRY_DEBTOR_GROUP) {
        $('#billToBill').show();
        $('#billToBillListInfo').hide();
        $('#editbillToBillListInfo').hide();
        this.customer_id = value.value;
        //this.getInvoiceByCustId(this.customer_id);
        //this.getInvoiceByCustId(value.value);
        //this.getVehicleInvoiceList(value.value);
      } else if (groupIdByLedgerId.data.Ledger.group_id != constDefault.SUNDRY_DEBTOR_GROUP) {
        //
        $('#billToBill').hide();
        $('#billToBillListInfo').hide();
        $('#editbillToBillListInfo').hide();
      }
    })

}



// Function to get Invoice for Bill to Bill data
getInvoiceByCustId(cust_id) {
  this.addVoucherForm.setControl('billToBillList', this.formBuilder.array([]));
  this.accountService.getInvoiceByCustIdNew(cust_id, this.petro_id)
    .subscribe(misCustList => {
      this.invoiceList = misCustList.data;
      //console.log(this.invoiceList[0].CreditInvoice);
      if (this.invoiceList.status != 204) {
        $('#billToBillListInfo').show();
        // this.invoiceList = Object.entries(misCustList.data).map(([value, label]) => ({value,label}));
        for (let i = 0; i < this.invoiceList.length; i++) {
          this.billToBillList = this.addVoucherForm.get('billToBillList') as FormArray;
          this.billToBillList.push(
            this.formBuilder.group({
              select: [''],
              invoice_id: [this.invoiceList[i].CreditInvoiceView.id, ''],
              total_amt: [this.invoiceList[i].CreditInvoiceView.total_balance, ''],
              date: [this.invoiceList[i].CreditInvoiceView.invoice_date, ''],
              invoice_no: [this.invoiceList[i].CreditInvoiceView.invoice_no, ''],
              bill_amount: [this.invoiceList[i].CreditInvoiceView.grand_total, ''],
              paid_amt: [this.invoiceList[i].CreditInvoiceView.total_paid, ''],
              balance: [this.invoiceList[i].CreditInvoiceView.balance, ''],
              pay_now: []
            })
          );
        }
      } else {

      }
    })
}


getInvoiceByCustIdPayment(cust_id) {
  this.editVoucherForm.setControl('editbillToBillList', this.formBuilder.array([]));
  this.accountService.getInvoiceByCustIdPayment(cust_id, this.petro_id)
    .subscribe(misCustList => {

if (misCustList.status==200) {

      this.editinvoiceList = misCustList.data;

      
      //console.log(this.invoiceList[0].CreditInvoice);
      if (this.editinvoiceList.status != 204) {
        $('#editbillToBillListInfo').show();
        // this.invoiceList = Object.entries(misCustList.data).map(([value, label]) => ({value,label}));
        for (let i = 0; i < this.editinvoiceList.length; i++) {
          this.editVoucher.edit_invoice_type=this.editinvoiceList[i].VoucherTransaction.type;
          this.editbillToBillList = this.editVoucherForm.get('editbillToBillList') as FormArray;
          this.editbillToBillList.push(
            this.formBuilder.group({
              select: [true],
              invoice_id: [this.editinvoiceList[i].VoucherTransaction.referances_id, ''],
              total_amt: [this.editinvoiceList[i].VoucherTransaction.total_amount, ''],
              date: [this.editinvoiceList[i].VoucherTransaction.date, ''],
              invoice_no: [this.editinvoiceList[i].VoucherTransaction.dm_no, ''],
              bill_amount: [this.editinvoiceList[i].VoucherTransaction.total_amount, ''],
              paid_amt: [this.editinvoiceList[i].VoucherTransaction.paid_amount, ''],
              balance: [this.editinvoiceList[i].VoucherTransaction.balance_amount, ''],
              pay_now: [this.editinvoiceList[i].VoucherTransaction.pay_now, '']
              
            })
          );
        }
      } else {

      }
    } //200
    else{
      $('#editbillToBillListInfo').hide();
    }
    })
}


total: number;
billToBill_total: number = 0;

// On key up of every balance of bill
calculate_bill(number1, j) {
  this.billToBill_total = 0;
  this.billToBillList = this.addVoucherForm.get('billToBillList') as FormArray;
  this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
  this.billToBillList.value.forEach((data, index) => {

    if (Number(this.billToBillList.value[index]['pay_now']) > Number(this.billToBillList.value[index]['balance'])) {
      //	$("#bill_pay_now_"+j).val(Number(this.billToBillList.value[index]['balance']));
      this.billToBillList.controls[index].get('pay_now').setValue(this.billToBillList.value[index]['balance']);

      //this.billToBillList.value[index]['pay_now']=this.billToBillList.value[index]['balance'];
      this.billToBill_total = this.billToBill_total + Number(this.billToBillList.value[index]['pay_now']);
      this.cr_amount = this.billToBill_total;
      this.addVoucherList.controls[0].get('amount').setValue(this.billToBill_total);
      //this.addVoucherForm['amount']=this.billToBill_total;
      this.addVoucher.amount = this.billToBill_total;
      console.log(this.addVoucher.amount);
      this.getTotal(0)

    } else {
      this.billToBill_total = this.billToBill_total + Number(this.billToBillList.value[index]['pay_now']);
      this.addVoucherList.controls[0].get('amount').setValue(this.billToBill_total);
      this.addVoucher.amount = this.billToBill_total;
      console.log(this.addVoucher.amount);
      this.getTotal(0)
    }
  })
}

calculate_bill_edit(number1, j) {
  this.billToBill_total = 0;
  this.editbillToBillList = this.editVoucherForm.get('editbillToBillList') as FormArray;
  this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
  this.editbillToBillList.value.forEach((data, index) => {

    if (Number(this.editbillToBillList.value[index]['pay_now']) > Number(this.editbillToBillList.value[index]['balance'])) {
      //	$("#bill_pay_now_"+j).val(Number(this.billToBillList.value[index]['balance']));
      this.editbillToBillList.controls[index].get('pay_now').setValue(this.editbillToBillList.value[index]['balance']);

      //this.billToBillList.value[index]['pay_now']=this.billToBillList.value[index]['balance'];
      this.billToBill_total = this.billToBill_total + Number(this.editbillToBillList.value[index]['pay_now']);
      this.cr_amount = this.billToBill_total;
      this.editVoucherList.controls[0].get('amount').setValue(this.billToBill_total);
      //this.addVoucherForm['amount']=this.billToBill_total;
      this.editVoucher.amount = this.billToBill_total;
      console.log(this.addVoucher.amount);
      this.getEditTotal(0)

    } else {
      this.billToBill_total = this.billToBill_total + Number(this.editbillToBillList.value[index]['pay_now']);
      this.editVoucherList.controls[0].get('amount').setValue(this.billToBill_total);
      this.editVoucher.amount = this.billToBill_total;
      console.log(this.editVoucher.amount);
      this.getEditTotal(0)
    }
  })
}

// check if invoice Entry is checked
isInvoiceChecked(event, id, j) {
  console.log(event.target.checked)
  this.billToBillList = this.addVoucherForm.get('billToBillList') as FormArray;
  if (event.target.checked) {
    this.billToBillList.controls[j].get('pay_now').setValue(this.billToBillList.value[j]['balance']);
    $("#bill_pay_now_" + j).removeAttr("readonly");
    this.calculate_bill(event, j);
  }
  if (!event.target.checked) {
    //alert("false");
    this.billToBillList.controls[j].get('pay_now').setValue('0');
    $("#bill_pay_now_" + j).attr("readonly", "readonly");
    this.calculate_bill(event, j);
  }
}

isEditInvoiceChecked(event, id, j) {
  console.log(event.target.checked)
  this.editbillToBillList = this.editVoucherForm.get('editbillToBillList') as FormArray;
  if (event.target.checked) {
    this.editbillToBillList.controls[j].get('pay_now').setValue(this.editbillToBillList.value[j]['balance']);
    $("#ebill_pay_now_" + j).removeAttr("readonly");
    this.calculate_bill_edit(event, j);
  }
  if (!event.target.checked) {
    //alert("false");
    this.editbillToBillList.controls[j].get('pay_now').setValue('0');
    $("#ebill_pay_now_" + j).attr("readonly", "readonly");
    this.calculate_bill_edit(event, j);
  }
}

getBillToBill() {		
  $('#billToBillListInfo').show();
  $('#add_item').hide();
  $("#aioConceptName :selected").text();
}

invoice_type: string
getInvoiceType() {
  this.billToBillList = this.addVoucherForm.get('billToBillList') as FormArray;
  this.invoice_type = $('#invoiceType :selected').val();
  if (this.invoice_type == "1") {
    $('#show_vehicle_info').hide();
    $('#add_item').hide();
    this.getInvoiceByCustId(this.customer_id);
  } else if (this.invoice_type == "2") {
    //
    $('#billToBillListInfo').hide();
    $('#show_vehicle_info').hide();
    $('#add_item').hide();
    this.getVehicleList(this.customer_id);
  } else {
    this.billToBillList = this.formBuilder.array([])
    console.log(this.billToBillList);
    //return
    $('#billToBillListInfo').hide();
    $('#show_vehicle_info').hide();
    $('#add_item').show();
  }
}

// Get invoice list for vehicle to vehicle data
getVehicleInvoiceList(vehicle_no) {
  vehicle_no = vehicle_no.value;
  if (vehicle_no) {
    this.addVoucherForm.setControl('billToBillList', this.formBuilder.array([]));
    this.shiftSaleService.getVehicleSaleInfoByCustId(this.customer_id, vehicle_no, this.petro_id)
      .subscribe(vehicleData => {
        this.invoiceList = vehicleData.data;
        if (vehicleData.status == 200) {
          $('#billToBillListInfo').show();
          this.invoiceList.forEach((vehicle, i) => {
            this.billToBillList = this.addVoucherForm.get('billToBillList') as FormArray;
            this.billToBillList.push(
              this.formBuilder.group({
                select: [''],
                invoice_id: [this.invoiceList[i].Sale.id, ''],
                total_amt: [this.invoiceList[i].Sale.total_amount, ''],
                bill_amount: [''],
                date: [this.invoiceList[i].Sale.sales_date, ''],
                invoice_no: [this.invoiceList[i].Sale.sale_no, ''],
                paid_amt: [this.invoiceList[i].Sale.total_payment, ''],
                balance: [this.invoiceList[i].Sale.total_balance, ''],
                pay_now: []
              })
            );
          })
        } else {
          this.no_vehicle_info = "Balance amount is Nill";
          $('#billToBillListInfo').hide();
        }
      });

  }
}


getVehicleList(customer_id) {
  $('#show_vehicle_info').show();
  this.shiftSaleService.getVehicleListByCustId(customer_id, this.petro_id)
    .subscribe(vehicleData => {
      this.vehicleDropdownList = Object.entries(vehicleData.data).map(([value, label]) => ({value,label}));
    })
}




 // Function to add Multiple voucher Entries (Add Voucher)
 addVoucherItem(): FormGroup {
  return this.formBuilder.group({
   dr_cr: [constDefault.CREDIT_LEDGER, Validators.required],
   ledger_id: ['', Validators.required],
   amount: ['', Validators.required]
  })
 }

 // Function to add Multiple voucher Entries (Edit Voucher)
 editVoucherItem(): FormGroup {
  return this.formBuilder.group({
   dr_cr: [constDefault.CREDIT_LEDGER, Validators.required],
   ledger_id: ['', Validators.required],
   ledger_name: ['', Validators.required],
   amount: ['', Validators.required],
   id: ['', Validators.required]
  })
 }

 // Function to Remove One Row in (Add Voucher Modal)
 removeItem(i) {
  if (confirm("Do you really want to Delete this Entry?")) {
   if (this.addVoucherList.length > 1) {
    this.addVoucherList.removeAt(i);
    this.getTotal(i)
   } else if (this.addVoucherList.length < 2) {
   }
  }
 }

 // Function to Remove One Row in Edit Voucher Table (Modal)
 removeEditItem(i) {
  if (confirm("Do you really want to Delete this Entry?")) {
   if (this.editVoucherList.length > 1) {
    this.editVoucherList.removeAt(i);
    this.getEditTotal(i)
   } else if (this.editVoucherList.length < 2) {
   }
  }
 }

 // Get Voucher List Fields for (Add) Form Array
 get addVoucherFormData() {
  return this.addVoucherForm.get('addVoucherList')
 }
	// Get billtobill payment list for FromArray
	get billToBillPayment() {
		return this.addVoucherForm.get('billToBillList')
	}

  get editbillToBillPayment() {
		return this.editVoucherForm.get('editbillToBillList')
	}
 // Get Voucher List Fields for (Edit) Form Array
 get editVoucherFormData() {
  return this.editVoucherForm.get('editVoucherList')
 }

 // Function to add One Row in Add Voucher Table (Add Modal)
 addItem() {
  this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
  this.addVoucherList.push(this.addVoucherItem());
 }

 // Function to add a row in Edit Voucher Table (Edit Modal)
 addEditItem() {
  this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
  this.editVoucherList.push(this.editVoucherItem());
 }

 cr_amount: number;
 dr_amount: number;
 // Calculate Total Amount
 getTotal(index) {
  this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
  this.cr_amount = 0;
  this.dr_amount = 0;
  this.addVoucherList.value.forEach((data, index) => {

   if (this.addVoucherList.value[index]['dr_cr'] == constDefault.CREDIT_LEDGER) {
    this.cr_amount = this.cr_amount + Number(this.addVoucherList.value[index]['amount']);
   }
   if (this.addVoucherFormData.value[index]['dr_cr'] == constDefault.DEBIT_LEDGER) {
    this.dr_amount = this.dr_amount + Number(this.addVoucherList.value[index]['amount']);
   }
  })
 }


 cr_total_view: number;
 dr_total_view: number;
 voucher_info: any = [];
 voucher_details: any = [];
 petro_name: string;
 v_date:string;
 v_no:number;
 test1: any[] = [];
 sum: number;
 viewStatus: boolean;
 // Get Voucher Data (Modal)
 getVoucherData(id, index) {
  this.test1 = [];
  this.sum = 0;
  this.viewStatus = true;
  $('#viewReceiptModal').modal('show');
  this.accountService.getReceiptVoucherDataById(id).subscribe(data => {
    
   this.voucher_info = data.data.Voucher;
   this.voucher_details = data.data.VoucherDetail;
   //console.log(this.voucher_details);
   this.petro_name = this.authData.petro_name;
   this.v_date = data.data.Voucher.date;
   this.v_no = data.data.Voucher.no;
   this.cr_total_view = 0;
   this.dr_total_view = 0;
   this.voucher_details.forEach((v_details, index) => {
    if (v_details.dr_cr == constDefault.CREDIT_LEDGER) {
     this.cr_total_view = Number(v_details.amount) + Number(this.cr_total_view);
    } else if (v_details.dr_cr == constDefault.DEBIT_LEDGER) {
     this.dr_total_view = Number(v_details.amount) + Number(this.dr_total_view);
    }
   })
   console.log(this.dr_total_view);
  });
 }


 // Get data to Edit Receipt voucher data by id
 edit_index: number;
 getEditVoucher(id, index) {
  this.editVoucherForm.setControl('editVoucherList', this.formBuilder.array([]));
  $('#editReceiptModal').modal('show');

  this.accountService.getReceiptVoucherDataById(id).subscribe(data => {
   //console.log(data);
   this.editVoucher.date = data.data.Voucher.date;
   this.editVoucher.vid = data.data.Voucher.id;
   if (data.data.Voucher.cheque_no == null) {
    //alert(data.data.Voucher.cheque_no);
    $('#edit_cheque_info').hide();
   } else {
    this.editVoucher.cheque_no = data.data.Voucher.cheque_no;
    this.editVoucher.cheque_date = data.data.Voucher.cheque_date;
   }

   this.editVoucher.amount = data.data.Voucher.amount;
   this.editVoucher.ledger_id = data.data.Ledger.id;
   //this.editVoucher.ledger_name = data.data.Ledger.name;
   this.editVoucher.ledger_name = {'value':data.data.Ledger.id,'label':data.data.Ledger.name};
   this.editVoucher.narration = data.data.Voucher.narration;

   for (let voucher_entry of data.data.VoucherDetail) {
    this.edit_index = 1;
    if (data.data.Voucher.ledger_id == voucher_entry.ledger_id) {

    } else {
     this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
     this.editVoucherList.push(
      this.formBuilder.group({
        id: [voucher_entry.id, Validators.required],
       dr_cr: [voucher_entry.dr_cr, Validators.required],
       ledger_id: [voucher_entry.ledger_id, Validators.required],
       ledger_name: [voucher_entry.Ledger.name, ''],
       amount: [voucher_entry.amount, '']
      })
     );
    this.getInvoiceByCustIdPayment(id);

    }
   }
   this.getEditTotal(this.edit_index)
  });
 }

 total_credit: number;
 total_debit: number;
 voucherDetails: any[];
 // Submit Voucher Entry
 addVoucherData(add_voucher) {
   
   add_voucher.submitted = false;
   this.pipe = new DatePipe('en-US'); 
   this.addVoucher.date= this.pipe.transform(this.addVoucher.date, 'yyyy-MM-dd');
   this.addVoucher.cheque_date= this.pipe.transform(this.addVoucher.cheque_date, 'yyyy-MM-dd');
  this.authData = this.session.get('authData');
  this.addVoucher.petro_id = this.authData.petro_id;

  this.addVoucher.ledger_id = this.addVoucher.ledger.value;
  this.addVoucher.voucherDetails = this.addVoucherList.value;
  if(this.addVoucher.ledger_id==this.voucher_ledger_type){
   this.addVoucher.cheque_no = "";
   this.addVoucher.cheque_date = "";
 }	
 this.addVoucher['type'] = this.invoice_type;
console.log(this.invoice_type);
 if (!this.invoice_type) {
  this.addVoucher.billToBillList = [];
} else {
  this.addVoucher.billToBillList = this.billToBillList.value;
}
  this.total_credit = Number(this.addVoucher.amount) + Number(this.dr_amount);
  if (this.cr_amount == this.total_credit) {
   this.accountService.addReceiptVoucher(this.addVoucher).subscribe(data => {
    if (data.status == 201) {
    
     this.success_message = 'Voucher has been created';
     this.cr_amount = 0;
       this.dr_amount = 0;
   //	$('#addReceiptModal').modal('hide');
     this.pageWiseData(1);
   //	this.addVoucherForm.reset();
   //	this.addVoucher.date = new Date(Date.now());
   this.addVoucherForm.setControl('addVoucherList', this.formBuilder.array([this.addVoucherItem()]));
         
            this.addVoucher.cheque_no='';
            this.addVoucher.amount='';
            
            this.addVoucher.narration='';
          this.addVoucher.cheque_date= new Date(Date.now()); 
     this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
     this.addVoucherList.controls[0].get('dr_cr').setValue(this.credit_ledger);

     $('#billToBillListInfo').hide();
     $('#show_vehicle_info').hide();
     $('#billToBill').hide();
     
    } else {
     //this.error_message = 'Item category could not be created';
     this.error_message = 'Voucher could not be created';
    }
   });
  } else {
   this.error_message = 'Debit amount and Total Amount does not match!';
  }
 }

 cr_Edit_amount: number;
 dr_Edit_amount: number;

 getEditTotal(index) {
  this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
  this.cr_Edit_amount = 0;
  this.dr_Edit_amount = 0;
  this.editVoucherList.value.forEach((data, index) => {

   if (this.editVoucherList.value[index]['dr_cr'] == constDefault.CREDIT_LEDGER) {
    this.cr_Edit_amount = this.cr_Edit_amount + Number(this.editVoucherList.value[index]['amount']);
   }

   if (this.editVoucherList.value[index]['dr_cr'] == constDefault.DEBIT_LEDGER) {
    this.dr_Edit_amount = this.dr_Edit_amount + Number(this.editVoucherList.value[index]['amount']);
   }
  })
 }


 // Edit Voucher data and Save Data 
 editVoucherData(edit_voucher) {
   edit_voucher.submitted=false;
   this.pipe = new DatePipe('en-US'); 
   this.editVoucher.date= this.pipe.transform(this.editVoucher.date, 'yyyy-MM-dd');
   this.editVoucher.cheque_date= this.pipe.transform(this.editVoucher.cheque_date, 'yyyy-MM-dd');
  this.editVoucher.voucherDetails = this.editVoucherList.value;
  this.editVoucher.ledger_id=this.editVoucher.ledger_name.value;
  console.log(this.editVoucher.ledger_name.value);
  if(this.editVoucher.ledger_id==this.voucher_ledger_type){
   this.editVoucher.cheque_no = "";
   this.editVoucher.cheque_date = "";
 }	
 if (!this.editVoucher.edit_invoice_type) {
  this.editVoucher.editbillToBillList = [];
} else {
  this.editVoucher.editbillToBillList = this.editbillToBillList.value;
}
  this.total_credit = Number(this.editVoucher.amount) + Number(this.dr_Edit_amount);
  if (this.cr_Edit_amount == this.total_credit) {

   this.accountService.editReceiptVoucher(this.editVoucher).subscribe(data => {
    if (data.status == 200) {
     this.success_message = 'Voucher has been Updated';
     $('#editbillToBillListInfo').hide();
     $('#show_vehicle_info').hide();
     $('#billToBill').hide();

     $('#editReceiptModal').modal('hide');
     this.pageWiseData(1);
     this.editVoucherForm.reset();
    } else {
     this.error_message = 'Error';
    }
   });
  } else {
   this.error_message = 'Credit amount and Total Amount does not match!';
  }
 }

 // Delete Voucher by id
 deleteVoucher(id, index) {
  if (confirm("Are you sure to delete this Voucher Entry")) {
   this.index = index;
   this.model = 'Voucher';
   //alert(id);
   this.shiftSaleService.deleteShiftReceivedVoucher(id).subscribe(data => {
    //console.log(data);
    if (data.status == 200) {
     this.voucherList.splice(this.index, 1);
     this.success_message = "Voucher deleted sucessfully";
    } else {
     this.error_message = "Voucher could not be deleted.";
    }
   });
   //this.editname=this.editCategoryData.name;	
  }
 }

 // Function to Search Voucher Data
 searchReceiptVoucher() {
   
   
   
     //this.shift_date= shiftData.date;
   
   //console.log(this.serachVoucherData);
  this.pageWiseData(1);
 }

 // Reset Search Data
 resetSearchReceiptVoucher() {
  this.serachVoucherData = {};
  this.pageWiseData(1);
 }

 // Pagination 
 pageWiseData(pageno) {
   //alert('getLocations');
   this.pipe = new DatePipe('en-US'); 
   this.serachVoucherData.from_date= this.pipe.transform(this.serachVoucherData.from_date, 'yyyy-MM-dd');
   this.serachVoucherData.to_date= this.pipe.transform(this.serachVoucherData.to_date, 'yyyy-MM-dd');
   this.currentPage = pageno;
   this.accountService.getReceiptVouchers(pageno, this.petro_id, this.serachVoucherData)
   .subscribe(alldata => {
     this.voucherList = alldata.data,
     this.totalPayVoucher = alldata.count
   });
 }

 // Get ledger value on change ledger list and set id to form array list option
 getLedgerId(value, i) {
  //console.log(value)
  this.editVoucherList = this.editVoucherForm.get('editVoucherList') as FormArray;
  this.editVoucherList.controls[i].get('ledger_id').setValue(value.value);
  this.check_add_miscllenious_cust(value);
  //console.log(this.editVoucherList)
 }

 // Print receipt voucher veiw details
 printComponent() {
   var printContents = document.getElementById("voucher_print").innerHTML;
   var popupWin = window.open('', '_blank', 'width=300,height=300');
   popupWin.document.open();
   popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="node_modules/bootstrap/dist/css/bootstrap.min.css" /></head><body onload="window.print()">' + printContents + '</body></html>');
   popupWin.document.close();
 }
 
 // Voucher Amount Keyup set amount
 // Amit
 onVoucherAmountKeyUp(event){
   this.addVoucherList = this.addVoucherForm.get('addVoucherList') as FormArray;
   this.addVoucherList.controls[0].get('amount').setValue(event.target.value);
   this.getTotal(0);
 }

 public findInvalidControls() {
  const invalid = [];
  const controls = this.editVoucherForm.controls;
  for (const name in controls) {
      if (controls[name].invalid) {
          invalid.push(name);
      }
  }
  console.log(invalid);
  return true;
}
}