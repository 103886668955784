import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-item-summary',
  templateUrl: './stock-item-summary.component.html',
  styleUrls: ['./stock-item-summary.component.css']
})
export class StockItemSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
