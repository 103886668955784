import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule }   from '@angular/forms';
import { AngularWebStorageModule } from 'angular-web-storage';
import { NgxPaginationModule} from  'ngx-pagination'; // <-- import the module
import { NgDatepickerModule } from 'ng2-datepicker';
//import {Injector} from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';


//export let InjectorInstance: Injector;

// No refresh module
//import {ROUTER_PROVIDERS} from '@angular/router';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';

// Export Export excel,pff...
// Note  : Don't install using  npm cli command
import { ExportAsModule } from 'ngx-export-as';

// Pipe
import { DatePipe } from '@angular/common';
import { MathAbsPipe } from './customePipe/math-abs.pipe';
import { ParseFloatPipe } from './customePipe/parse-float.pipe';
import { NumberToWordsPipe } from './customePipe/number-to-words.pipe';

import { AppComponent } from './app.component';


import { RouterModule, Routes,PreloadAllModules } from '@angular/router';		
import { AdminLoginComponent } from './admin-pages/login/login.component';
import { LoginService } from './admin-services/login.service';																																			
import { AdminDashboardComponent } from './admin-pages/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard'; // for login and user authentication and authorisation 
import { LeftNavigationComponent } from './admin-layout/left-navigation/left-navigation.component';
import { TopHeaderComponent } from './admin-layout/top-header/top-header.component';
import { AdminInnerComponent } from './admin-layout/admin-inner/admin-inner.component';
import { LogoutComponent } from './admin-pages/logout/logout.component';
import { CategoryMasterComponent } from './admin-pages/category-master/category-master.component';
import { DashboardComponent } from './super-admin-pages/dashboard/dashboard.component';
import { SuperAdminInnerComponent } from './super-admin-layout/super-admin-inner/super-admin-inner.component';
import { SuperAdminLeftNavigationComponent } from './super-admin-layout/super-admin-left-navigation/super-admin-left-navigation.component';
import { SuperAdminTopHeaderComponent } from './super-admin-layout/super-admin-top-header/super-admin-top-header.component';
import { PetrolPumpListComponent } from './super-admin-pages/petrol-pump-list/petrol-pump-list.component';
import { ItemMasterComponent } from './admin-pages/item-master/item-master.component';
import { LocationMasterComponent } from './admin-pages/location-master/location-master.component';
import { VehicleMasterComponent } from './admin-pages/vehicle-master/vehicle-master.component';
import { CustomerMasterComponent } from './admin-pages/customer-master/customer-master.component';
import { TankMasterComponent } from './admin-pages/tank-master/tank-master.component';
import { DispenserMasterComponent } from './admin-pages/dispenser-master/dispenser-master.component';
import { NozzleMasterComponent } from './admin-pages/nozzle-master/nozzle-master.component';
import { UniqueValidationDirective } from './shared/unique-validation.directive';
import { UniqueEmailDirective } from './shared/unique-email.directive';
import { ParicelistMasterComponent } from './admin-pages/paricelist-master/paricelist-master.component';
import { OpeningstockMasterComponent } from './admin-pages/openingstock-master/openingstock-master.component';
import { CardMasterComponent } from './admin-pages/card-master/card-master.component';
import { ShiftSaleTransactionsComponent } from './admin-pages/shift-sale-transactions/shift-sale-transactions.component';
import { UserMasterComponent } from './admin-pages/user-master/user-master.component';
import { PurchaseListComponent } from './admin-pages/purchase-list/purchase-list.component';
import { GroupMasterComponent } from './admin-pages/accounts/group-master/group-master.component';
import { LedgerMasterComponent } from './admin-pages/accounts/ledger-master/ledger-master.component';
import { PaymentVoucherComponent } from './admin-pages/accounts/payment-voucher/payment-voucher.component';
import { LedgerReportComponent } from './admin-pages/account-reports/ledger-report/ledger-report.component';
import { ReceiptVoucherComponent } from './admin-pages/accounts/receipt-voucher/receipt-voucher.component';
import { ContraVoucherComponent } from './admin-pages/accounts/contra-voucher/contra-voucher.component';
import { OnCreatedDirective } from './shared/on-created.directive';
import { JournalVoucherComponent } from './admin-pages/accounts/journal-voucher/journal-voucher.component';
import { DesignationMasterComponent } from './admin-pages/designation-master/designation-master.component';
import { EmployeeMasterComponent } from './admin-pages/employee-master/employee-master.component';
import { SlBankDepositeComponent } from './admin-pages/shift-sale-transactions/sl-bank-deposite/sl-bank-deposite.component';
import { SlExpensesComponent } from './admin-pages/shift-sale-transactions/sl-expenses/sl-expenses.component';
import { SlReceiptComponent } from './admin-pages/shift-sale-transactions/sl-receipt/sl-receipt.component';
import { CreditInvoiceComponent } from './admin-pages/credit-invoice/credit-invoice.component';
import { SlCardPaymentComponent } from './admin-pages/shift-sale-transactions/sl-card-payment/sl-card-payment.component';
import { SlSaleComponent } from './admin-pages/shift-sale-transactions/sl-sale/sl-sale.component';
import { SlNozzleReadingComponent } from './admin-pages/shift-sale-transactions/sl-nozzle-reading/sl-nozzle-reading.component';
import { BankReconciliationComponent } from './admin-pages/accounts/bank-reconciliation/bank-reconciliation.component';
import { CreditNoteListComponent } from './admin-pages/credit-note-list/credit-note-list.component';
import { TrialBalanceComponent } from './admin-pages/account-reports/trial-balance/trial-balance.component';
import { TrialLedgerComponent } from './admin-pages/account-reports/trial-ledger/trial-ledger.component';
import { CustomerBillLedgerComponent } from './admin-pages/account-reports/customer-bill-ledger/customer-bill-ledger.component';
import { ReminderMasterComponent } from './admin-pages/reminder-master/reminder-master.component';
import { SlWalletSaleComponent } from './admin-pages/shift-sale-transactions/sl-wallet-sale/sl-wallet-sale.component';
import { EditReceiptVoucherLedgerComponent } from './admin-pages/account-reports/ledger-report/edit-receipt-voucher-ledger/edit-receipt-voucher-ledger.component';
import { EditContraVoucherLedgerComponent } from './admin-pages/account-reports/ledger-report/edit-contra-voucher-ledger/edit-contra-voucher-ledger.component';
import { EditJournalVoucherLedgerComponent } from './admin-pages/account-reports/ledger-report/edit-journal-voucher-ledger/edit-journal-voucher-ledger.component';
import { SlDipStockComponent } from './admin-pages/shift-sale-transactions/sl-dip-stock/sl-dip-stock.component';
import { ApplicationSetupComponent } from './admin-setup/application-setup/application-setup.component';
import { OfferMasterComponent } from './admin-pages/offer-master/offer-master.component';
import { SlQuickLinksComponent } from './admin-pages/shift-sale-transactions/sl-quick-links/sl-quick-links.component';
import { OwnerListComponent } from './super-admin-pages/owner-list/owner-list.component';
import { ShiftReportComponent } from './admin-pages/sale-report/shift-report/shift-report.component';
import { AllCustomerCreditWiseComponent } from './admin-pages/sale-report/all-customer-credit-wise/all-customer-credit-wise.component';
import { SelectedCustomerCreditWiseComponent } from './admin-pages/sale-report/selected-customer-credit-wise/selected-customer-credit-wise.component';
import { BillToBillOutstandingComponent } from './admin-pages/account-reports/bill-to-bill-outstanding/bill-to-bill-outstanding.component';
import { StockSummaryComponent } from './admin-pages/stock-reports/stock-summary/stock-summary.component';
import { CustomerOutstandingComponent } from './admin-pages/account-reports/customer-outstanding/customer-outstanding.component';
import { CustomerStatementComponent } from './admin-pages/sale-report/customer-statement/customer-statement.component';
import { SlStockComponent } from './admin-pages/shift-sale-transactions/sl-stock/sl-stock.component';
import { GstSaleReportComponent } from './admin-pages/sale-report/gst-sale-report/gst-sale-report.component';
import { ItemWisePurchaseSaleComponent } from './admin-pages/stock-reports/item-wise-purchase-sale/item-wise-purchase-sale.component';
import { StockTransferReportComponent } from './admin-pages/stock-reports/stock-transfer-report/stock-transfer-report.component';
import { LocationWiseStockReportComponent } from './admin-pages/stock-reports/location-wise-stock-report/location-wise-stock-report.component';
import { GroupWiseSaleReportComponent } from './admin-pages/stock-reports/group-wise-sale-report/group-wise-sale-report.component';
import { ItemListReportComponent } from './admin-pages/stock-reports/item-list-report/item-list-report.component';
import { StockVariationComponent } from './admin-pages/accounts/stock-variation/stock-variation.component';
import { PurchaseReportComponent } from './admin-pages/stock-reports/purchase-report/purchase-report.component';
import { SaleCashCreditReportComponent } from './admin-pages/stock-reports/sale-cash-credit-report/sale-cash-credit-report.component';

import { StockItemSummaryItemComponent } from './admin-pages/stock-reports/stock_item_summary/stock-item-summary-item/stock-item-summary-item.component';
import { StockItemSummaryMonthComponent } from './admin-pages/stock-reports/stock_item_summary/stock-item-summary-month/stock-item-summary-month.component';
import { StockItemSummaryDateComponent } from './admin-pages/stock-reports/stock_item_summary/stock-item-summary-date/stock-item-summary-date.component';
import { StockItemSummaryGroupComponent } from './admin-pages/stock-reports/stock_item_summary/stock-item-summary-group/stock-item-summary-group.component';
import { ProfitAndLossComponent } from './admin-pages/account-reports/profit-and-loss/profit-and-loss.component';
import { UserPrivilegesComponent } from './admin-pages/user-privileges/user-privileges.component';
import { LeftAuthNavigationComponent } from './admin-layout/left-auth-navigation/left-auth-navigation.component';
import { PumpRegistrationComponent } from './web-pages/pump-registration/pump-registration.component';
import { BalanceSheetComponent } from './admin-pages/account-reports/balance-sheet/balance-sheet.component';
import { SalesDsrComponent } from './admin-pages/sale-report/sales-dsr/sales-dsr.component';
import { MiscCutomerStatementComponent } from './admin-pages/sale-report/misc-cutomer-statement/misc-cutomer-statement.component';
import { DeliverStatusNoteComponent } from './web-pages/deliver-status-note/deliver-status-note.component';
import { StateMasterComponent } from './super-admin-pages/state-master/state-master.component';
//import { CityMasterComponent } from './super-admin-pages/city-master/city-master.component';
import { CityMasterComponent } from './admin-pages/city-master/city-master.component';
import { ChangepasswordComponent } from './admin-setup/changepassword/changepassword.component';
import { OtherCreditInvoiceComponent } from './admin-pages/other-credit-invoice/other-credit-invoice.component';
import { DsmReportComponent } from './admin-pages/account-reports/dsm-report/dsm-report.component';
import { FinancialMasterComponent } from './super-admin-pages/financial-master/financial-master.component';
import { UniqueMobileDirective } from './shared/unique-mobile.directive';
import { PaymentGatewayComponent } from './admin-pages/payment-gateway/payment-gateway.component';
import { BozerMasterComponent } from './admin-pages/bozer-master/bozer-master.component';
import { CustomerAgeingComponent } from './admin-pages/account-reports/customer-ageing/customer-ageing.component';
import { DailyCustomerSaleComponent } from './admin-pages/sale-report/daily-customer-sale/daily-customer-sale.component';
import { ShiftReportCust1Component } from './admin-pages/sale-report/shift-report-cust1/shift-report-cust1.component';
import { BozerstockComponent } from './admin-pages/stock-reports/bozerstock/bozerstock.component';
import { UnbilledComponent } from './admin-pages/sale-report/unbilled/unbilled.component';
import { CustomerCostComponent } from './admin-pages/account-reports/customer-cost/customer-cost.component';
import { CustomerCenterComponent } from './admin-pages/customer-center/customer-center.component';
import { DsmSumComponent } from './admin-pages/account-reports/dsm-sum/dsm-sum.component';

//import { StockItemSummaryComponent } from './admin-pages/stock-reports/stock-item-summary/stock-item-summary.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { StockItemSummaryComponent } from './admin-pages/stock-reports/stock-item-summary/stock-item-summary.component';


export const ROUTES: Routes =[
	{
		path: 'login',
		component: AdminLoginComponent,
		
	  },
	  {
		path: 'pump-registartion',
		component: PumpRegistrationComponent,
		
	  },
	  
	  {
		  path: 'deliverStatusNote', 
	    component: DeliverStatusNoteComponent
	  },
	  { path: '',
		  component: AdminLoginComponent
	  },
	  
	
	  
	  // Admin Paths
	  { path: 'admin', // This is main routing for particular module
		component: AdminInnerComponent,
		 
		 
		
		// canActivate:[AuthGuard],
		// Children User for admin pages
		// canActivate:[AuthGuard] use to check login status	
		children: [
			{ path: '', component: AdminDashboardComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'dashboard', component: AdminDashboardComponent, canActivate:[AuthGuard] ,data: {  expectedRole: 2 }   },
			{ path: 'categoryMaster', component: CategoryMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'itemMaster', component: ItemMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'locationMaster', component: LocationMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'vehicleMaster', component: VehicleMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'bozerMaster', component: BozerMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'customerMaster', component: CustomerMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'dispenserMaster', component: DispenserMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'tankMaster', component: TankMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'nozzleMaster', component: NozzleMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'pricelistMaster', component: ParicelistMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'openingStockMaster', component: OpeningstockMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'cardMasterComponent', component: CardMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'paygate', component: PaymentGatewayComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'designationMasterComponent', component: DesignationMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },			
			{ path: 'employeeMasterComponent', component: EmployeeMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },			
			{ path: 'shiftSaleEntry', component: ShiftSaleTransactionsComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'userMaster', component: UserMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'purchaseList', component: PurchaseListComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'creditNoteList', component: CreditNoteListComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'groupMaster', component: GroupMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'ledgerMaster', component: LedgerMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'paymentVoucher', component: PaymentVoucherComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'bankReconciliation', component: BankReconciliationComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'ledgerReport', component: LedgerReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'customerBillToBillReport', component: CustomerBillLedgerComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'trialBalance', component: TrialBalanceComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'trial-ledger', component: TrialLedgerComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'receiptVoucher', component: ReceiptVoucherComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'contraVoucher', component: ContraVoucherComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'journalVoucher', component: JournalVoucherComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'creditInvoice', component: CreditInvoiceComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'othercreditInvoice', component: OtherCreditInvoiceComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'reminderMasterComponent', component: ReminderMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'offerMaster', component: OfferMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'admin-setup', component: ApplicationSetupComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 },   },
			{ path: 'change-password', component: ChangepasswordComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 },   },
			{ path: 'shiftReport', component: ShiftReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'allCustomerCreditWise', component: AllCustomerCreditWiseComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'selectedCustomerCreditWise', component: SelectedCustomerCreditWiseComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'bill-to-bill-outstanding', component: BillToBillOutstandingComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'stockSummaryReport', component: StockSummaryComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'customer_outstanding', component: CustomerOutstandingComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'customer_ageing', component: CustomerAgeingComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'customer_statement', component: CustomerStatementComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'gst_sale_reoport', component: GstSaleReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'itemWiseSalePurchaseReport', component: ItemWisePurchaseSaleComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'stockTransferReport', component: StockTransferReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'locationWiseStockReport', component: LocationWiseStockReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'groupWiseSaleReport', component: GroupWiseSaleReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'itemListReport', component: ItemListReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'stockVariation', component: StockVariationComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'purchaseReport', component: PurchaseReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'saleCashCreditReport', component: SaleCashCreditReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'stockItemSummary', component: StockItemSummaryItemComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'stockItemSummaryDateWise', component: StockItemSummaryDateComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'stockItemSummaryMonthWise', component: StockItemSummaryMonthComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }   },
			{ path: 'profitAndLoss', component: ProfitAndLossComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'userPrivileges', component: UserPrivilegesComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'balanceSheet', component: BalanceSheetComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'saleDsr', component: SalesDsrComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'miscCustomerStatement', component: MiscCutomerStatementComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			//{ path: 'super-admin/stateMaster', component: StateMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'cityMaster', component: CityMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'dsmreport', component: DsmReportComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'dailyreport', component: DailyCustomerSaleComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'custreport1', component: ShiftReportCust1Component, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'bowzerstock', component: BozerstockComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  } ,	
			{ path: 'unbilled', component: UnbilledComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  }	,
			{ path: 'groupoutstand', component: CustomerCostComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  },
			{ path: 'customercenter', component: CustomerCenterComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  }	,
			{ path: 'dsmsum', component: DsmSumComponent, canActivate:[AuthGuard],data: {  expectedRole: 2 }  }	
			
		],
		data : { 
            expectedRole : 2
          }
		
	  },
	  { path: 'logout', 
	  		component: LogoutComponent
		},

		// Super Admin Paths
		{ path: 'super-admin', 
			component: SuperAdminInnerComponent,
			// Children Super-admin
			children: [
				{ path: '', component: DashboardComponent, canActivate:[AuthGuard],data: {  expectedRole: 1 }  },
				{ path: 'dashboard', component: DashboardComponent, canActivate:[AuthGuard],data: {  expectedRole: 1 }  },
				{ path: 'petrolPumpList', component: PetrolPumpListComponent, canActivate:[AuthGuard],data: {  expectedRole: 1 }  },
				{ path: 'ownerList', component: OwnerListComponent, canActivate:[AuthGuard],data: {  expectedRole: 1 }  },
				{ path: 'stateMaster', component: StateMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 1 }  },
			    { path: 'financialMaster', component: FinancialMasterComponent, canActivate:[AuthGuard],data: {  expectedRole: 1 }  }
			],
			canActivate:[AuthGuard],
			data: {  expectedRole: 1 } 
	  }
	  
];
@NgModule({
  declarations: [
    AppComponent,
    AdminLoginComponent,
    AdminDashboardComponent,
    LeftNavigationComponent,
    TopHeaderComponent,
	AdminInnerComponent,
	LogoutComponent,
	CategoryMasterComponent,
	DashboardComponent,
	SuperAdminInnerComponent,
	SuperAdminLeftNavigationComponent,
	SuperAdminTopHeaderComponent,
	PetrolPumpListComponent,
	ItemMasterComponent,
	LocationMasterComponent,	
	VehicleMasterComponent,
	CustomerMasterComponent,
	TankMasterComponent,
	DispenserMasterComponent,
	NozzleMasterComponent,
	UniqueValidationDirective,
	UniqueEmailDirective,
	ParicelistMasterComponent,
	OpeningstockMasterComponent,
	CardMasterComponent,
	ShiftSaleTransactionsComponent,
	UserMasterComponent,
	PurchaseListComponent,
	GroupMasterComponent,
	LedgerMasterComponent,
	PaymentVoucherComponent,
	LedgerReportComponent,
	ReceiptVoucherComponent,
	ContraVoucherComponent,
	OnCreatedDirective,
	MathAbsPipe,
	ParseFloatPipe,
	NumberToWordsPipe,
	JournalVoucherComponent,
	DesignationMasterComponent,
	EmployeeMasterComponent,
	SlBankDepositeComponent,
	SlExpensesComponent,
	SlReceiptComponent,
	CreditInvoiceComponent,
	SlCardPaymentComponent,
	SlSaleComponent,
	SlNozzleReadingComponent,
	BankReconciliationComponent,
	CreditNoteListComponent,
	TrialBalanceComponent,
	TrialLedgerComponent,
	CustomerBillLedgerComponent,
	ReminderMasterComponent,
	SlWalletSaleComponent,
	EditReceiptVoucherLedgerComponent,
	EditContraVoucherLedgerComponent,
	EditJournalVoucherLedgerComponent,
	SlDipStockComponent,
	ApplicationSetupComponent,
	OfferMasterComponent,
	SlQuickLinksComponent,
	OwnerListComponent,
	ShiftReportComponent,
	AllCustomerCreditWiseComponent,
	SelectedCustomerCreditWiseComponent,
	BillToBillOutstandingComponent,
	StockSummaryComponent,
	CustomerOutstandingComponent,
	CustomerStatementComponent,
	SlStockComponent,
	GstSaleReportComponent,
	ItemWisePurchaseSaleComponent,
	StockTransferReportComponent,
	LocationWiseStockReportComponent,
	GroupWiseSaleReportComponent,
	ItemListReportComponent,
	StockVariationComponent,
	PurchaseReportComponent,
	SaleCashCreditReportComponent,
	StockItemSummaryItemComponent,
	StockItemSummaryMonthComponent,
	StockItemSummaryDateComponent,
	StockItemSummaryGroupComponent,
	ProfitAndLossComponent,
	UserPrivilegesComponent,
	LeftAuthNavigationComponent,
	PumpRegistrationComponent,
	BalanceSheetComponent,
	SalesDsrComponent,
	MiscCutomerStatementComponent,
	DeliverStatusNoteComponent,
	StateMasterComponent,
	CityMasterComponent,
	ChangepasswordComponent,
	OtherCreditInvoiceComponent,
	DsmReportComponent,
	FinancialMasterComponent,
	UniqueMobileDirective,
	PaymentGatewayComponent,
	BozerMasterComponent,
	CustomerAgeingComponent,
	DailyCustomerSaleComponent,
	ShiftReportCust1Component,
	BozerstockComponent,
	UnbilledComponent,
	CustomerCostComponent,
	CustomerCenterComponent,
	DsmSumComponent,
	StockItemSummaryComponent


  ],
  imports: [
    BrowserModule,
	FormsModule,
	ReactiveFormsModule,
	NgxPaginationModule,
	HttpClientModule,
	AngularWebStorageModule,
	NgDatepickerModule,
	NgSelectModule,
	ChartsModule,
	CKEditorModule,
	 ExportAsModule,
	BrowserAnimationsModule,
	ToastrModule.forRoot(),
	 RouterModule.forRoot(ROUTES,{ preloadingStrategy: PreloadAllModules }
      //{ enableTracing: true } // <-- debugging purposes only
   ) 
	
  ],
  providers: [LoginService,AuthGuard,DatePipe,{ provide : LocationStrategy , useClass : HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { 

}

