import { Component, OnInit } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router , ActivatedRoute } from '@angular/router';
import { ApplicationSetupService } from '../../admin-services/application-setup.service';
import { CommonsService } from '../../admin-services/commons.service';
import { format } from 'date-fns';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  reportData: any[] = [];

    value: any = null;
  constructor(private router:Router, private commonService:CommonsService,private applicationService:ApplicationSetupService, private session: SessionStorageService, private localStorage:LocalStorageService, private route:ActivatedRoute,public toastr: ToastrManager) {
		// For page refresh
		this.route.queryParams.subscribe((data) => {
		  if (data && data['refresh']) {
			
			window.location.href = "#/admin/dashboard";

			location.reload();
		  }
		}); 
  }
  authData:any = {};
  petro_id:number;
  success_message: string;
	error_message: string;
  add_loading:false;
	dragging:any={};
  total_sale: string;
  credit_sale: string;
  cash_sale: string;
  other_sale: string;
  hsd_sale: string;
  ms_sale: string;
  card_sale: string;
  wallet_sale: string;
  reminderList:any[] = [];
  notify:any='false';
  ngOnInit() {
	  	
   // this.value =this.sessionStr.getItem('userData');
   this.authData = this.session.get('authData');

   this.notify=this.localStorage.get('notify');
   console.log(this.localStorage.get('notify'));
   console.log(this.notify);
    this.petro_id = this.authData.petro_id;
   this.value = this.session.get('name');
   this.commonService.getSaleCashCreditReportData(this.petro_id)
   .subscribe(reportAllData => {
     if(reportAllData.status==200)
     {
     
      this.reportData=reportAllData.data;
      //console.log(this.reportData);
      for(let i=0; i<this.reportData.length; i++){
        this.total_sale=this.reportData[i].totalsale; //use i instead of 0
        this.hsd_sale=this.reportData[i].HSDLtr;
        this.ms_sale=this.reportData[i].MSLtr;
        this.other_sale=this.reportData[i].othersale;

        this.credit_sale=this.reportData[i].creditsale;
        this.cash_sale=this.reportData[i].cashsale;
        this.card_sale=this.reportData[i].cardsale;
        this.wallet_sale=this.reportData[i].walletsale;
    }

     }

      

     

       
   });
    
 

   console.log('hello');
   this.showCustom(); 
   this.showSuccess();

   
  }
  


  // High Priority:- Delete All Data From Database by petro_id (By Petrolpump Id) 
  // clearData(){
  //   if(confirm("Do you really want to Delete all Data?")){ 
	// 		this.applicationService.deletedAllDataFormTable(this.petro_id).subscribe(data=>{
	// 			if(data.status==201)
	// 			{				
	// 			  this.success_message="Data Is Cleared From Database.";		
	// 			}else{
	// 				this.error_message="Data Could Not Be Deleted.";
	// 			}
	// 		});
	// 	}
  // }

  showSuccess() {
    // this.toastr.successToastr('This is success toast.', 'Success!',
    //   { enableHTML: true,
    //     dismiss:'click',
    //     position: 'top-full-width',
       
    //     showCloseButton:true
    //    }
    // );


    // this.toastr.customToastr(
    //   reminderHtmlContent,
    //   'Reminder Notification',,
    //   { enableHTML: true,
    //     dismiss:'click',
    //     position: 'top-full-width',
    //     showCloseButton:true
    //    }
    //   );
}

showError() {
    this.toastr.errorToastr('This is error toast.', 'Oops!');
}

showWarning() {
    this.toastr.warningToastr('This is warning toast.', 'Alert!');
}

showInfo() {
    this.toastr.infoToastr('This is info toast.', 'Info');
}

showCustom() {
console.log(this.notify,'notify');
  if(this.notify=='true'){
   
  let reminderHtmlContent = '';
  this.commonService.getReminderListData(1, this.petro_id, '')
    .subscribe(alldata => {
      if (alldata.data && alldata.data.length > 0) {
        this.reminderList = alldata.data;
 
        this.reminderList.forEach(item=>{
          reminderHtmlContent += `
          <div style="margin-bottom: 10px; text-transform: uppercase;">
            <span>
              <strong>${item.Reminder.event_name} on 
              ${new Date(item.Reminder.rem_date).toLocaleDateString('en-US', { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
              })}
              </strong>
            </span>
          </div>
        `;
        }) 
        
        this.toastr.customToastr(
          `<span style=" color: #0c5460; background-color: #d1ecf1; border-color: #bee5eb;">`+reminderHtmlContent+`<span>`,
          'Reminder Notification',
          { enableHTML: true,
            dismiss:'click',
            position: 'top-full-width',
            showCloseButton:true,
            titleClass:'toastr-title'
           }
          );
        // $('#addNotificationModal').modal('show');
        this.localStorage.set('notify','false');
        this.notify='false';
      }


    });

  }
}



}
